.div-attention {
  padding-top: 20px;
  padding-bottom: 20px;
  .attention {
    padding-top: 1px;
    padding-left: 5px;
    color: #ff8f00;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
}
.div-radio {
  padding-top: 20px !important;
}

.radio-group {
  padding-top: 20px !important;
}
