@import "../../../styles/variables";

.password-tooltip ul.password-checklist {
  list-style: none; /* Remove default list style */
  padding: 0px; padding-top: 1em;
  overflow: auto; /* Container has same height as content */
}

.password-tooltip ul.password-checklist li {
  position: relative;
  padding-left: 30px; /* Distance for checkbox symbol */
  margin-bottom: 10px;
}

.password-tooltip ul.password-checklist li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #808080; /* Checkbox border */
  border-radius: 50%; /* Rounded symbol */
  background-color: transparent; /* Transparent background */
}

.password-tooltip ul.password-checklist li.checked::before {
  border-color: #808080;
}

.password-tooltip ul.password-checklist li.checked::after {
  content: "✓";
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translate(-15%, -40%);
  color: $color-primary; /* Color of checkmark */
  font-size: 14px;
  font-weight: bold;
}

