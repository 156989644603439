@import "../../../styles/variables";

.twofactor-box-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .twofactor-title {
    padding-left: 60px;
    padding-top: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 39px;
  }

  .step {
    padding-left: 60px;
    padding-top: 0px;
    color: #a5a5a5;
    font-size: 14px;
  }

  .twofactor-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding-top: 5px;
    width: 410px;
    margin: auto;
    @include query(mobile) {
      padding-top: 70px;
      padding-left: 10px;
      width: 300px;
    }
    @include query(tablet) {
      padding-top: 70px;
      width: 300px;
    }
  }

  .form-item {
    width: 100%;
  }
  .anotherLogin {
    display: flex;
    color: #3795fa;
    justify-content: center;
    width: 100%;
    cursor: pointer;
  }
  .sent-description {
    padding-top: 20px;
    font-size: 16px;
    color: #5f5f5f;
    line-height: 25px;
  }

  .down-description {
    text-align: center;
    padding-top: 16px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.38);
    line-height: 20px;
  }

  .resend-code-btn {
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.38);
    letter-spacing: 1.25px;
    cursor: pointer;

    &.enabled {
      color: white;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .padding-30 {
    padding-top: 30px;
  }

  .counter {
    display: flex;
    justify-content: center;
    margin-left: -5px;
    font-size: 12px;
    color: $textGrey;
    &.red {
      color: #d32f2f;
    }
  }

  .anotherAccount {
    color: #2196f3;
  }
}
