@import "../../../styles/variables";

$placeholderColor: rgba(0, 0, 0, 0.6);

%customeInput {
  background: $white;
  color: $textGrey;
  height: $input-height;
  font-size: $input-font-size;
  border: 1px solid $borderGrey;
  border-radius: 8px;
  box-sizing: border-box;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: 1rem;
}

.input {
  @extend %customeInput;
  width: 100%;
}

.input:focus {
  outline-style: none;
  box-shadow: none;
  border-color: $borderFocusedGrey;
}

.label {
  color: $labelGrey;
  font-size: $input-font-size;
}

.text-area {
  padding: 20px;
}

::placeholder {
  color: $placeholderColor;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  color: $placeholderColor;
}

::-ms-input-placeholder {
  color: $placeholderColor;
}

.select {
  color: $placeholderColor;
}
option:not(:first-of-type) {
  color: $textGrey;
}

.checkbox {
  height: 16px;
  accent-color: #007ad5 !important;
  margin-top: 4px;
  margin-right: 5px;
  font-size: $input-font-size;
  padding: 0px;

  .label {
    color: #212529;
  }
}

%InputGroupBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: $white;
  outline: none;
  &:focus {
    outline: none;
  }
  img {
    width: 25px;
    height: 25px;
  }
}

%custom-datePicker {
  @extend %customeInput;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: left;
  cursor: pointer;
}

.datepicker {
  border: none;
  box-sizing: border-box;
  color: $textGrey;
  width: 100%;
  &:focus {
    outline: none;
    border: none;
  }
}

.datePickerBox {
  @extend %custom-datePicker;

  .groupBtn {
    @extend %InputGroupBtn;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
