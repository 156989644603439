
.cta-btn {
  font-size: 14px;
  border-radius: 10px;
  width: fit-content;
  margin-bottom: 1em;
  padding-right: 2em;
  padding-left: 2em;
  //padding-top: 0px;
  height: 36px;
  cursor: pointer;
}

.cta-btn.blue {
  background: #1565c0;
  color: #fff;
}