@import "./variables";

.dx-searchbox .dx-placeholder::before,
.dx-searchbox .dx-texteditor-input {
  padding-left: 5px !important;
}
.dx-datagrid-headers {
  background: rgba(33, 33, 33, 0.08);
  color: rgba(0, 0, 0, 0.87) !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center !important;
  padding-top: 8px;
  padding-bottom: 8px;
}

/* class="dx-datagrid-table dx-datagrid-table-fixed" */
.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  //text-align: center !important;
  padding-left: 10px;
  padding-right: 10px;
}
.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  //text-align: center !important;
  padding-left: 10px;
  padding-right: 10px;
}
.dx-header-row
  > td
  > .dx-datagrid-text-content:not(.dx-sort-indicator):not(.dx-header-filter-indicator) {
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border-bottom: none !important;
}
.dx-header-row td {
  height: 2rem;
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
}

.justTitleWrap {
  position: absolute;
  z-index: 200;
  margin-top: 25px !important;
  margin-right: 10px !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td {
  vertical-align: middle !important;
}

.dx-data-row {
  font-size: 14px;
  height: 3rem;
  color: rgba(0, 0, 0, 0.87);
}

.dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable {
  // background-color: #f5f5f5 !important;
}

.dx-scrollable-scroll-content {
  height: 5px !important;

  display: block !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  padding: 0px;
}

.titleWrap .separator {
  height: 35px;
  width: 1px;
  background-color: rgb(204, 204, 204);
  z-index: 100;
  margin-right: 5px;
  // margin-top: 8px;
}
.titleGrid {
  z-index: 100;

  font-size: 22px;
  color: rgb(120, 128, 117);
  font-weight: 600;
}

.titleWrap {
  position: absolute;
  margin-top: 40px;
  margin-right: 10px !important;
  display: flex;
  align-items: center;
}
.loading {
  height: 100vh;
}

.dx-datagrid {
  background-color: inherit !important;
}

.statusContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;
}
