@import "./variables";

html {
  font-family: "Roboto", sans-serif !important;
}
body {
  background: $white;
  box-sizing: border-box;
  overflow:overlay;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-height: 100vh;
  margin: 0px;
  padding: 0px;
}

.title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
}

.small-title {
  color: #666;
  font-size: 15px;
}

.panel-container {
  width: 100%;
}

.header {
  background: $color-primary;
  display: flex;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  color: $white;

  .logo-div {
    margin-left: 32px !important;
    margin-top: 12px !important;
  }

  .btnProfile {
    border: none;
    width: 40px;
    height: 40px;
    background-color: $color_primary;
    color: white;
    margin-right: 15px !important;
    margin-top: 12px;
    padding-left: 10px;
  }

  .avatar {
    width: 32px;
    margin-right: 8px;
    margin-top: 5px;
  }

  .fullname {
    align-self: center;
    color: $white;
  }

  .profile-menus-div {
    z-index: 1000;
    position: absolute;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 25px;
    color: rgba(0, 0, 0, 0.87);
    background-color: $white;
    width: 300px;
    right: 32px;
    top: 54px;
    box-shadow: 0px 5px 5px 0px #00000033;

    .fullname {
      font-size: 16px;
    }
    .item {
      font-size: 14px;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: rgba(0, 0, 0, 0.6);
      flex: none;
      order: 1;
      flex-grow: 1;
      text-align: left;
      .link {
        margin-top: -4px;
        padding-left: 10px;
        cursor: pointer;
      }
    }
  }
}

.dashboard-wrapper {
  @include query(desktop) {
    display: flex;
  } 
  width: 100%;
  padding: 0px;
  min-height: 85vh;  
  overflow-x: hidden;
}

.padding-40 {
  padding-top: 40 !important;
}
.SnackbarItem-message {
  font-size: 16px;
}

.scroll-tiny::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.scroll-tiny::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.scroll-tiny::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.87);
}


.container-card {
  min-height: 85vh;  
  width:calc(100vw - 275px);
  flex: 4;
  padding: 2rem;
  padding-left: 72px;
  padding-right: 72px;
  padding-top: 70px;
  background-color: white;
  @include query(mobile) {
    padding-left: 5px;
    padding-right: 5px;
    width: 100vw ;
  }
  @include query(tablet) {
    padding-left: 15px;
    padding-right: 15px;
    width: 100vw ;
  }
}
