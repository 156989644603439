@import "../../../styles/variables";

.box-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .box-title {
    padding-left: 60px;
    padding-top: 50px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 39px;
  }

  .step {
    padding-left: 60px !important;
    padding-top: 0px;
    color: #a5a5a5;
    font-size: 14px;
  }

  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding-top: 55px;
    padding-bottom: 75px;
    width: 410px;
    margin: auto;
    @include query(mobile) {
      padding-top: 70px;
      padding-left: 10px;
      width: 300px;
    }
    @include query(tablet) {
      padding-top: 70px;
      width: 300px;
    }
  }

  .form-item {
    width: 100% !important;
  }
  .anotherLogin {
    display: flex;
    color: #3795fa;
    justify-content: center;
    width: 100%;
    cursor: pointer;
  }

  .down-description {
    text-align: center;
    padding-top: 16px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.38);
    line-height: 20px;
  }

  .padding-30 {
    padding-top: 30px;
  }

  .anotherLogin {
    color: #2196f3;
  }
}
