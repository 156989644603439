@import "../../../styles/variables";

.sidebar-wrapper {
  overflow: hidden;
  width: 275px !important;
  min-height: calc(100vh - 56px);
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.87);
  .sidebar-menu {
    list-style: none;
    color: $white;
    margin: 0;
    padding: 0;
    .workspace {
      display: flex;
      flex-direction: column;
      min-height: 90px;
      padding-bottom: 10px;
      background-color: black;
      color: white;
      .group {
        font-size: 14px;
        color: #666;
        padding-top: 20px;
        padding-left: 20px;
      }
      .div-name {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        padding-left: 20px;
        padding-right: 30px;
      }
    }
    .item {
      a {
        display: block;
        font-size: 14px;
        padding: 0.7em;
        transition: all ease-in 0.2s;
        border-bottom: 1px solid black;

        &.active {
          background: rgba(255, 255, 255, 0.1) !important;
          padding: 0.7em 0.8em;
          color: white;
        }
      }

      .icon {
        display: inline-flex; align-items: center; justify-content: center;
        width: 24px;
        margin-right: 6px;
        transform: translateY(3px); /* Make icons look centered */
        padding: 0 !important;
        color: $white !important;
        font-size: 16px;
      }
    }

    a {
      text-decoration: none;
      color: $white !important;
    }

    .submenu {
      list-style: none;
      padding: 0.5em 1.3em 0;

      li {
        padding: 0.5em;
        border-bottom: 1px dashed #ddd;

        .icon {
          margin-right: 6px;
          color: $white;
        }
      }
    }
  }
}

.seller-container {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  .seller-btn {
    //   position: absolute;
    //   bottom: 10px;
    margin-top: 15px; 
    width: 220px;
    background-color: #059189;
    color: white;
    border-radius: 8px;
    padding: 10px 30px;
    text-align: center;
    cursor: pointer;
  }
}

.menu-name {
  display: flex;
  justify-content: space-between;
  padding-left: 5px !important;
  font-size: 16px !important;
}

.unverified-span {
  font-size: 12px;
  color: #888;
  cursor: default;
}

.continue-span {
  font-size: 12px;
  color: #1565c0;
  cursor: pointer;
}

/* Make partners menu scrollable */
#workspace-menu {
  > .MuiPaper-root {
    overflow-y: auto;
  }
}
