@import "../../../styles/variables";

.login-box-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top:40px;

  .login-box {
    padding-top: 45px;
    width: 350px;
    @include query(mobile) {
      padding-top: 70px;
      width: 300px;
    }
    @include query(tablet) {
      padding-top: 70px;
      width: 300px;
    }
  }
  .login-title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 39px;
  }

  .padding-20 {
    padding-top: 20px;
  }
  .padding-30 {
    padding-top: 30px;
  }
  .padding-50 {
    padding-top: 50px;
  }

  .margin-30 {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .margin-10 {
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .form-item {
    width: 100%;
  }
  .forgot { /* Forgot Ratior key? and Forgot password? buttons */
    display: flex;
    color: #3795fa;
    justify-content: center;
    padding-top: 8px;
    cursor: pointer;
  }
  .forgot.ratior-key {
    justify-content: flex-end;
  }
  .checkboxrow {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include query(mobile) {
      padding-top: 30px;
      flex-direction: column;
    }
  }

  .errormsg {
    color: red;
  }
}
