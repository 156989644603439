$color-primary: #207b75;
$color-secondary: #c19b23;
$background-color: #f9f9f9;
$mediumGrey: #8e8e8e;
$gridFontColor: #575757;
$labelGrey: #767676;
$textGrey: #5e5d5d;
$borderGrey: #ced4da;
$borderFocusedGrey: rgb(151, 151, 151);
$white: white;

$input-height: 3rem;
$input-font-size: 1.2rem;
$title-font-size: 1.5rem;

$sm: 480px;
$md: 1024px;
$lg: 20000px;

$bp: (
  mobile: $sm,
  tablet: $md,
  desktop: $lg,
);

@mixin query($screen) {
  @each $key, $value in $bp {
    @if ($screen==$key) {
      @media screen and (max-width: $value) {
        @content;
      }
    }
  }
}
