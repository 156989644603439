@import "../../../styles/variables";

.div-pass-strength {
  display: flex;
  justify-content: space-between;
  border: none;
  padding-left: 8px;
  padding-right: 8px;
}
.hr-strength {
  height: 2px !important;
  border-radius: 5px;
  width: 23%;
  opacity: 100%;
  color: rgba(0, 0, 0, 0.12);
  &.pred {
    color: #d32f2f;
  }
  &.porange {
    color: #ff8f00;
  }
  &.pgreen {
    color: #388e3c;
  }
}

.div-tooltip {
  background-color: #e3f2fd !important;
  padding: 20px !important;
}

.form-row {
  justify-content: center;
  width: 100%;
  display: flex;
  justify-content: center;
  @include query(mobile) {
    flex-direction: column;
  }
}

.form-item-row {
  width: 350px !important;
  margin-right: 20px !important;
  @include query(mobile) {
    width: 300px !important;
  }
}

.form-item-col {
  width: 100%;
}
