@import "../../../styles/variables";

.button {
  border-radius: 8px;
  color: white;
  font-size: 14px;
  padding-left: 2rem;
  padding-right: 2rem;
  cursor: pointer;
  border: none;
  margin-top: 0px;
  height: 40px;
}

.button:focus {
  outline: none;
}
.button:focus-visible {
  box-shadow: 0 0 0 1px white, 0 0 0 3px #1565c0;
}

.primary {
  background-color: #1565c0;
  border-color: #1565c0;
}
.secondary {
  background-color: $color-secondary;
  border-color: $color-secondary;
}
.tertiary {
  background-color: $white !important;
  color: $color-primary !important;
  border: 1px solid $color-primary !important;
}

.no-border {
  border: none;
  background-color: $white !important;
  color: #1565c0 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.disabled {
  background-color: rgb(207, 207, 207);
  border-color: $background-color;
  cursor: auto;
}
