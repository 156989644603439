@import "../../../styles/variables";

.span-check {
  padding-top: 30px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  line-height: 24px;
}
.div-brief {
  margin-top: 20px;
  padding: 20px 20px;
  background-color: #e3f2fd;
  border-radius: 8px;
}

.link-btn {
  cursor: pointer;
  font-size: 14px;
  padding-right: 20px;
  align-content: center !important;
  color: #1565c0;
  letter-spacing: 1.25px;
  padding-top: 10px !important;
  &:hover {
    text-decoration: underline;
  }
}

.form-row {
  width: 100%;
  display: flex;
  @include query(mobile) {
    flex-direction: column;
  }
}

.div-btn {
  padding-top: 40px !important;
  display: flex;
  justify-content: flex-end !important;
  width: 100%;
  @include query(mobile) {
    justify-content: center;
  }
}

.cell-header {
  padding-top: 32px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
}
.cell-value {
  padding-top: 8px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
}
