@import "../../../styles/variables";

.link-btn {
  cursor: pointer;
  font-size: 14px;
  padding-right: 20px;
  align-content: center !important;
  color: #1565c0;
  letter-spacing: 1.25px;
  padding-top: 10px !important;
  &:hover {
    text-decoration: underline;
  }
}

.form-row {
  width: 100%;
  display: flex;
  @include query(mobile) {
    flex-direction: column;
  }
}

.div-btn {
  display: flex;
  justify-content: flex-end !important;
  width: 100%;
  @include query(mobile) {
    justify-content: center;
  }
}
