.divAddress {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(33, 33, 33, 0.08);
  padding: 20px 20px 20px 20px !important;
  margin-top: 30px !important;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  .h-title {
    color: rgba(0, 0, 0, 0.6);
  }
  .icon {
    padding-right: 15px;
    padding-left: 15px;
    color: rgba(0, 0, 0, 0.6);
  }
}
