@import "../../../styles/variables";

.phone-input {
  width: 100% !important;
  color: $textGrey !important;
  height: $input-height !important;
  font-size: $input-font-size !important;
  border-style: none !important;
}

.phone {
  width: 100% !important;
  border: 1px solid $borderGrey !important;
  border-radius: 8px;
  color: $textGrey !important;
  //height: $input-height !important;
  font-size: $input-font-size !important;
}

.phone-input-small{
  font-size: 16px !important;
  &.disabled{
    color: rgba(0, 0, 0, 0.38) !important;
    background-color: white !important;
  }
}


