.modal-content {
  display: flex !important;
  //align-items: center;
  width: 100% !important;
  max-width: 400px;
  border: none !important;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px;

  &.md {
    width: 100% !important;
    max-width: 555px;
  }
}

.lg {
  width: 100% !important;
  max-width: 700px;
}

.modal-title {
  padding-top: 10px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600;
  text-align: center;
}

@media (max-width: 768px) {
  .modal-content {
    padding-left: 1px;
    padding-right: 1px;
  }
}

.modal-content .content {
  padding-top: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0px;
  color: rgba(0, 0, 0, 0.6);
}

.modal-content .content p {
  flex: 6;
  margin: 0;
  max-width: 420px;
  //color: #5f5f5f;
  line-height: 1.3em;
}

.modal-content .content span:not(.MuiFormControlLabel-root span) {
  font-weight: 500;
  flex: 0;
  font-size: 1.7em;
  margin-right: 10px;
}

.div-btn {
  display: flex;
  padding-top: 10px !important;
  justify-content: end;
  align-content: flex-end;
  width: 100%;
}

.cta-btn {
  border-radius: 10px;
  width: fit-content;
  margin-bottom: 1em;
  padding-right: 2em;
  padding-left: 2em;
  //padding-top: 0px;
  height: 36px;
  cursor: pointer;
}

.cta-btn.left {
  margin: 2em 0 1em;
  padding: 0.5em 1.8em;
}

.cta-btn.blue {
  background: #1565c0;
  color: #fff;
}

.cta-btn.green {
  background: #219b64;
  color: #fff;
}

.cta-btn.red {
  background: #ff6363;
  color: #fff;
}

.cta-btn.white {
  background: #fff;
  border: none;
  color: #1565c0;
}

.cta-btn.border {
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
}
.cta-btn.right {
  float: right;
  padding-top: 10px;
}

.padding10 {
  padding-top: 10px;
}

.cta-btn.confirm {
  vertical-align: middle;
  margin-left: 10px;
}

@keyframes highlightBounce {
  0%, 45%, 55%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
}
.cta-btn.highlight {
  animation: highlightBounce 3s infinite;
}

.react-responsive-modal-modal {
  margin: 1.2rem;
  max-width: calc(100% - 1.2rem * 2);
  border-radius: 10px;
}

@media (max-width: 768px) {
  .react-responsive-modal-modal {
    margin: 4px;
    max-width: calc(100% - 4px * 2);
  }
}
