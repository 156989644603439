@import "../../../styles/variables";
.icon {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  margin-right: 0px;
  padding-right: 0px;
  color: rgba(0, 0, 0, 0.6) !important;
  cursor: pointer !important;
}
.grid-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  padding-right: 0px !important;
  margin-right: 0px !important;
  width: 100%;
  gap: 4px;
}
.grid-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  padding-right: 0px !important;
  margin-right: 0px !important;
  width: 100%;
  gap: 4px;
}

.dx-row.dx-header-row>td {
  border-right: 1px solid rgba(0, 0, 0, 0.08); /* Make border to resize columns visible */
}

.dx-row>td.word-wrap {
  white-space: normal;
}

.add-span {
  font-size: 14px;
}

.btn-small {
  padding-bottom: 15px;
}
