.div-address-btn {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 50px;
  justify-content: center;
  align-items: center;
  .description {
    text-align: center;
    padding-bottom: 20px;
  }
}

.btn-next {
  display: flex;
  justify-content: end;
  padding-top: 40px;
  gap: 8px;
}

@media (max-width: 768px) {
  .btn-next {
    flex-direction: column;
    align-items: flex-end;
  }
}