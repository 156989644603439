@keyframes highlightBounce {
    0%, 45%, 55%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.03);
    }
  }
.highlight {
    animation: highlightBounce 3s infinite;
}