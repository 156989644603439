.cell-header {
  padding-top: 10px !important;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.87);
}
.cell-value {
  padding-top: 8px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
}

.info-header {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}
.storage-cell-value {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
}
