@import "./variables";

.accordion-button {
  background-color: black !important;
  color: $white !important;
  height: 72px !important;
  padding-top: 22px !important;
  padding-left: 20px !important;
  padding-right: 30px !important;
  min-width: 256px !important;
  border: none !important;
  .collapsed {
    color: $white !important;
    background-color: black !important;
    box-shadow: 0 0 0 0 !important;
    border: none !important;
  }
}

.accordion-button::after {
  background-image: url("../assets/images/arrowup.svg") !important;
}

.accordion-button.collapsed::after {
  background-image: url("../assets/images/arrowup.svg") !important;
  transform: rotate(-180deg);
}

.accordion-button:focus {
  z-index: 3;
  border: none !important;
  outline: 0;
  box-shadow: 0 0 0 0 !important;
}

.accordion-button:not(.collapsed) {
  color: $white !important;
  background-color: black !important;
  box-shadow: 0 0 0 0 !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../assets/images/arrowup.svg") !important;
}

.accordion-item:first-of-type {
  border: none !important;

  .accordion-button {
    border: none !important;
  }
}

.accordion-item:last-of-type {
  border: none !important;

  .accordion-button {
    border: none !important;
    &.collapsed {
      border: none !important;
    }
  }
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border: none !important;
}
