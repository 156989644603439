@import "../../styles/variables";

.home-wrapper {
  display: flex;
  background-color: white;
  @include query(tablet) {
    flex-direction: column;
  }

  ul {
    list-style-type: none;
    padding-top: 35px;
    padding-left: 56px;
  }
  li {
    float: left;
  }

  li a {
    display: block;
    padding-right: 40px;
    color: rgba(0, 0, 0, 0.87);
    font-style: normal;
    font-size: 20px;
  }

  a:link {
    text-decoration: none;
  }
  a:link:visited {
    text-decoration: none;
  }

  a:link:hover {
    text-decoration: underline;
  }

  a:link:active {
    text-decoration: underline;
  }

  .col-container {
    display: flex;
    flex-direction: column;
    width: (100%);
  }

  .div-right {
    display: flex;
    justify-content: end !important;
    width: 100% !important;

    .register {
      height: 100vh;
      @include query(tablet) {
        width: 100%;
        margin-top: 30px;
      }
    }

    .home {
      height: 100vh;
      object-fit: cover;
      @include query(tablet) {
        width: 100%;
      }
    }

    .logo {
      position: absolute;
      padding-top: 38px;
      padding-right: 30px !important;
      width: 180px;
      border: none;
    }
  }

  .footer {
    display: flex;
    color: #9a9a9a;
    box-sizing: border-box;
    padding-top: 30px;
    padding-left: calc(((100% - 367px) / 2) + 45px);
    width: 100%;
    height: 2.5rem;
  }
  .footer2 {
    display: flex;
    color: #9a9a9a;
    box-sizing: border-box;
    font-size: 75%;
    padding-top: 10px;
    padding-left: calc(((100% - 367px) / 2) + 45px);
    width: 100%;
    height: 2.5rem;
  }
}

.under-construction{
  height: 100vh;
  width: 100vw;
  background-size: 100% 100%;
  background-image:linear-gradient(to bottom, rgba(5, 132, 124, 0.9), rgba(232, 203, 112, 0.7)), url('../../assets/images/underConstruction.jpg');
}
