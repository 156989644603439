@import "../../../styles/variables";

.register-title {
  align-self: flex-start;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 39px;
  padding-left: 56px !important;
  margin-top: 33px !important;
}
.step {
  padding-left: 56px !important;
  padding-top: 0px;
  color: #a5a5a5;
  font-size: 14px;
}

.register-box-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;

  .register-box {
    flex-direction: column;
    padding-top: 0px;
    margin: auto;
  }
  .padding-10 {
    padding-top: 10px;
  }

  .padding-20 {
    padding-top: 20px;
  }
  .padding-30 {
    padding-top: 30px;
  }

  .padding-50 {
    padding-top: 50px;
  }

  .margin-30 {
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .form-row {
    justify-content: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }

  .form-check-row {
    flex-wrap: nowrap;
  }

  .form-item {
    width: 350px !important;
    margin-right: 20px !important;
  }

  .form-item-address {
    width: 350px !important;
  }

  .div-btn-register {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .link-btn {
      flex: 1;
      max-width: 250px;
    }

    .d-flex {
      flex: 2;
      max-width: 200px;
      height: 100%;
      align-items: center;
    }

    .btn-action {
      width: 100%;
      margin-left: 30px;
      @include query(mobile) {
        margin-right: 0px !important;
      }
    }

    .link-btn {
      cursor: pointer;
      font-size: 14px;
      align-content: center !important;
      color: #1565c0;
      letter-spacing: 1.25px;
      padding: 10px !important;
      background-color: white;
      &:hover {
        text-decoration: underline;
      }
      &.disabled {
        cursor: default;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.38);
      }
    }
  }

  .imgback {
    padding-right: 8px;
    margin-top: -3px;
  }

  .btn-Code {
    margin-top: 20px;
    width: 100%;
    color: $white;
  }

  .activation-text {
    padding-top: 42px;
    color: #5f5f5f;
    font-size: 16px;
  }

  .phone-text {
    font-weight: 600;
    font-size: 18px;
  }
  .span-2fa {
    color: $textGrey;
    font-weight: 600;
    font-size: 18px;
  }

  .counter {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 30px;
    margin-left: -5px;
    font-size: 25px;
    color: $textGrey;
    &.red {
      color: #d32f2f;
    }
  }

  .resend-code-btn {
    width: 100%;
    display: flex;
    margin-left: -2px;
    justify-content: center;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.38);
    letter-spacing: 1.25px;

    &.enabled {
      color: #1565c0;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .blue {
    text-decoration: underline;
    color: #2196f3;
    cursor: pointer;
  }
  .checkme {
    font-size: 16px;
  }
  .icon {
    cursor: pointer;
    &.disabled {
      cursor: default;
    }
  }
}
