@import "../../../styles/variables";
.code-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}
.code-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: center;
    height: 85px;
    width: 85px;
    max-width: 15vw; /* Limit width for no overflow on mobile */
    border-radius: 8px;
    margin: 0 10px;
    border: 1px solid $borderGrey;
    font-size: 38px;
    color: rgba(0, 0, 0, 0.87);
  }
  input:focus {
    outline: none;
  }
  // input:nth-child(4n) {
  //   margin-right: 24px;
  // }
}
